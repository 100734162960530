.container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  width: 100%;
}

.fieldset {
  margin-top: 1.6rem;
  border-radius: 15px;
  border-color: #f2c23342;
  border-style: solid;
  padding: 1.5rem;
}

.fieldset > legend {
  color: #f2c233;
  font-size: 1.2rem;
  font-family: Roboto;
  font-weight: 600;
}

.label {
  color: "#f2c233";
  font-size: "1rem";
  font-family: "Roboto";
  font-weight: 600;
}

input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
  color: #959595;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.flex{
  display: flex;
  gap: 2rem;
  width: 100%;
  
  div{
    width: 100%;
  }
}
