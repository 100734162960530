.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
}

.label {
  color: #f2c233;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.select {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
  color: #959595;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15px;
}
