.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: min-content;
  padding: 10px;
  border: 2px solid #f2c23378;
  border-radius: 10px;
}

.nav {
  display: flex;

  p {
    font-size: 1rem;
    padding: 5px;
  }

  button {
    border: 1px solid grey;
    cursor: pointer;
    background-color: white;
    border-radius: 5px;
  }

  button:hover {
    background-color: rgb(185, 185, 185);
  }
}
