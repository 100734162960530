.tableTopContributors {
    width: 100%;
    max-width: 1015px;
    border-collapse: collapse;
    margin: 1.5rem auto;

    thead {
        position: sticky;
        top: 0;
        z-index: 1;

        th {
            text-align: left;
            color: black;
            background-color: #f2c233;
            font-size: 1rem;
            font-family: Roboto;
            padding-block: 1rem;
            padding-inline: 1rem;

            p {
                color: black;
            }
        }

        th:nth-child(1) {
            width: 65%;
        }

        th:nth-child(2) {
            width: 15%;
        }

        th:nth-child(3) {
            width: 20%;
        }

        .tableHeaderEmailTextMobile {
            display: none;
        }
    }

    tbody {
        tr {
            td {
                font-size: 1rem;
                font-weight: 500;
                padding-block: 1rem;
                padding-inline: 1rem;

                p {
                    color: black;
                }
            }
        }

        tr:nth-child(2n) {
            background-color: #c3bebe;
        }

        tr:nth-child(2n + 1) {
            background-color: #f5f5f5;
        }
    }

    .tableDataEmailAportes,
    .tableDataEmailTotal {
        display: none;
    }
}

@media (max-width: 800px) {
    .tableTopContributors {

        .tableHeaderAportes,
        .tableDataAportes {
            display: none;
        }

        .tableDataEmail {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .tableDataEmailAportes {
            display: block;
        }
    }
}

@media (max-width: 550px) {
    .tableTopContributors {
        thead {

            .tableHeaderEmailText,
            .tableHeaderTotal {
                display: none;
            }

            .tableHeaderEmailTextMobile {
                display: block;
            }
        }

        tbody {
            .tableDataEmailTotal {
                display: block;
            }

            .tableDataTotal {
                display: none;
            }
        }
    }
}