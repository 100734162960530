.container{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  column-gap: 2rem;
  row-gap: 1rem;

  label{
    grid-column: 1/2;
  }

  .saveBtn{
    grid-column: 4/5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .switchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  
    span {
      color: #f2c233;
      font-size: 1rem;
      font-family: Roboto;
      font-weight: 600;
    }
  }
  
}
