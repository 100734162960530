.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.label {
  color: #f2c233;
  font-size: 1rem;
  font-family: Roboto;
  font-weight: 600;
}

.switchContainer {
  display: flex;
  justify-content: space-between;
}

.switchContainer > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.commissionSwitch {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

.commissionSwitch > div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flex {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: space-between;
}

.flex > div {
  width: 100%;
}
