.loginBox {
  width: 326px;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.loginBoxTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2c233;
  color: black;
  font-weight: 500;
  height: 50px;
  font-family: 'Roboto';
  border-radius: 10px 10px 0px 0px;
}
