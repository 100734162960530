.table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
    
    thead{
        background-color: #f2f2f2;
        color: #000;
        font-weight: bold;

        .thead_tr{
            border: 1px solid #000;
            padding: 8px;

            th{
                padding: 8px;
                text-align: start;
            }
        }
    }

    tbody{
        .tbody_tr{
            border: 1px solid #000;
            padding: 8px;

            td{
                padding: 8px;
            }
        }
    }
}