.button {
    width: min(40%, 260px);
    height: 40px;
    background-color:black;
    color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto';
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ffd65c;
    padding-left: 20px;
    padding-right: 20px;
}

.button:disabled {
    background-color: #D9D9D9;
    color: #949494;
    cursor: not-allowed;
    border: 20px;
}

.button:disabled:hover {
    cursor: not-allowed;
    background-color: #D9D9D9;
    color: #949494;
}

.button:hover {
    background-color:#fcc92f;
    color: black;
}