.text {
    color: #f2c233;
    font-size: 16px;
    font-weight: 600;
}

.genericInput {
    width: 100%;
    background: #FFFFFF;
    border: 2px solid #EFEFEF;
    border-radius: 5px; 
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    mix-blend-mode: normal;
    opacity: 0.6;
}