.gridContainer{
  display: grid;
  grid-template-columns: 2fr 1fr;
  row-gap: 0.5rem;

  .btn{
    grid-row: 5;
    grid-column: 1/3;
  }
}

.fileInput {
  grid-column: 2;
  grid-row: 1/4;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    cursor: pointer;
    height: 40px;
    background-color: #393938;
    color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto";
    font-size: 16px;
    cursor: pointer;
    width: 200px;
  }

  input:focus + label,
  input + label:hover {
    background-color: grey;
  }

  img {
    width: 200px;
    background-color: #f9f9f9;
  }

  div {
    width: 200px;
    height: 200px;
    border: 1px solid black;
    border-style: dashed;
  }
}
