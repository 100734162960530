.fileInput {
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    cursor: pointer;
    height: 40px;
    border: 1px solid #ffd65c;
    background-color: black;
    color: white;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    gap: 10px;
    align-items: center;
    font-family: "Roboto";
    font-size: 1rem;
    cursor: pointer;
    min-width: 240px;

    span {
      width: 100%;
      text-align: center;
    }

    svg{
      fill: white;
    }
  }

  label:hover {
    background-color: #fcc92f;
    color: black;

    svg{
      fill: black;
    }
  }
}
