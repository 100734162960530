.container {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
}

.overflowContainer {
  max-height: 500px;
  overflow-y: auto;
  margin: 1.5rem 0;
}

.overflowContainer > .container {
  margin-top: 0;
}

.container thead {
  position: sticky;
  top: 0;
  z-index: 1;
  th {
    text-align: left;
    color: black;
    background-color: #f2c233;
    font-size: 1rem;
    font-family: Roboto;
    padding-block: 1rem;
    padding-inline: 1rem;
  }
}

.container tbody tr td {
  font-size: 1rem;
  font-weight: 500;
  padding-block: 1rem;
  padding-inline: 1rem;
}

.container tr:nth-child(2n) {
  background-color: #c3bebe;
}

.container tr:nth-child(2n + 1) {
  background-color: #f5f5f5;
}

.w100 {
  width: 100%;
}
