.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.barContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #F5F5F5;
    height: 29px;
    margin-inline: 1rem;
    color: grey;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}


.progress {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #f2c233;
    height: 29px;
    color: black;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

p {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    color: #f2c233;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0 !important;
}

.percentageIn {
    text-indent: 5px;
}

.percentageOut {
    text-indent: 5px;
    font-size: 0.8rem;
}