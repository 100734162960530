.container {
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:10px;
    flex-direction: column;
    
}
