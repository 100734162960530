.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 3rem;
  row-gap: 1.5rem;
}

.container > button {
  align-self: flex-end;
  justify-self: center;
  width: 50%;
  grid-column: 1/3;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: #f2c233;
    font-size: 1rem;
    font-family: Roboto;
    font-weight: 600;
  }
}
