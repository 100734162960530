.box {
    box-sizing: border-box;
    width: 339px;
    height: 217px;
    background: #333333;
    border: 3px solid #f2c233;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 0.5rem;
    border-radius: 20px;
    gap: 30px;
}

h3 {
    width: 160px;
    color: white;
    margin: 0;
}

h2 {
    margin: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color: #f2c233;
}