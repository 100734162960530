.containerInfoBox {

    min-height: 80px;
    justify-content: space-between;
    background: #F9F9F9;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px;
    height: auto;

    h2 {
        padding: 1rem;
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
        margin: auto;
        height: auto;
        width: auto;
        max-width: 90%;
    }

    p {
        padding: 1rem;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }

    span {
        color: #B79964;
    }
}