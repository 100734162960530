.container {
    position:fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.103);
    backdrop-filter: blur(2px);
    z-index: 10;
}

.loading {
    position:fixed;
    top:35%;
    left:50%;
}