.fieldset {
    margin-top: 1.6rem;
    border-radius: 15px;
    border-color: #f2c23342;
    border-style: solid;
    padding: 1.5rem;
}

.fieldset>legend {
    color: #f2c233;
    font-size: 1.2rem;
    font-family: Roboto;
    font-weight: 600;
}

.pdfContainer {
    margin: 0 auto;
}