.formBodyMail {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  .imagesMailContainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 10px;
    row-gap: 2rem;

    .bodyMailExample {
      display: flex;
      flex-direction: column;
      gap: 10px;
      border: 1px solid black;
      border-style: dashed;
      width: 210px;
      grid-column: 1;
      margin: 0 auto;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .bodyMailHeaderExample {
      border: 0px;
      border-bottom: 1px solid black;
      border-style: dashed;
      height: 100%;
      min-height: 50px;
      max-height: 70px;
      object-fit: cover;
    }

    .bodyMailFooterExample {
      border: 0px;
      border-top: 1px solid black;
      border-style: dashed;
      height: 100%;
      min-height: 50px;
      max-height: 70px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;

      }
    }

    .bodyMailCuerpoExample {
      font-size: 0.9rem;
      white-space: break-spaces;
      text-align: center;
      font-weight: 500;
    }
  }

  .fileInput {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
  }

  .saveBtn {
    grid-row: 1;
    grid-column: 1/3;
    display: flex;
    justify-content: center;
    margin-left: auto;
  }
}