.tableBtn {
    text-align: center;
  
    svg {
      cursor: pointer;
    }
  }

.noRewards {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: #f2c233;
  margin: 0;
  line-height: 1.5rem;
  overflow-y: hidden;
}