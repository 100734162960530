.flexContainer{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.switchContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  span {
    color: #f2c233;
    font-size: 1rem;
    font-family: "Roboto";
    font-weight: 600;
  }
}
