.reportContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
}

.reportHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.dateInputsContainer {
    display: flex;
    gap: 20px;
}

.dateInputContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        font-size: 14px;
        font-weight: 600;
        color: #ffd65c;
    }
}

.dateInput {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.buttonsContainer {
    display: flex;
    gap: 10px;
}

.reportForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.resportTableContributor{
    display: flex;
    flex-direction: column;
    gap: 5px;

    p{
        color: #000;
    }
}