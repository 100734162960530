.fieldset {
  margin-top: 1.6rem;
  border-radius: 15px;
  border-color: #f2c23342;
  border-style: solid;
  padding: 1.5rem;

  form {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;

    .fileInput {
      grid-column: 2;
      grid-row: 1/6;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 15px;

      input {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }

      label {
        cursor: pointer;
        height: 40px;
        background-color: #393938;
        color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Roboto";
        font-size: 16px;
        cursor: pointer;
        width: 200px;
      }

      input:focus + label,
      input + label:hover {
        background-color: grey;
      }

      img {
        width: 200px;
      }

      div {
        width: 200px;
        height: 200px;
        border: 1px solid black;
        border-style: dashed;
      }
    }
  }
}

.fieldset > legend {
  color: #f2c233;
  font-size: 1.2rem;
  font-family: Roboto;
  font-weight: 600;
}

.addProductButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}

.boxButtonsAddProductForm {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  grid-row: 8;
  grid-column: 1/3;
}

.tableProducts {
  width: 100%;
  border-collapse: collapse;
  margin: 1.5rem 0;
}

.noProducts {
  margin-top: 2rem;
}

.tableBtn {
  text-align: center;

  svg {
    cursor: pointer;
  }
}

.btnDisabled {
  text-align: center;
  svg {
    fill: gray;
  }
}
