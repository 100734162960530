.container {
    position:fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.103);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10;
    top: 0;
    left: 0;
}

.modal {
    background-color: #fff;
    min-width: 300px;
    max-width: 400px;
    min-height: 200px;
    border-radius: 10px;
    z-index:10;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    display:flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap:10px;
    flex-direction: column;
    padding: 0.8rem;
}

.title {
    font-weight: 600;
    margin-bottom: 20px;
}

.message {
    margin-bottom: 30px;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 20px;
}