.container {
    display:flex;
    flex-direction: column;
    width: 80%;
    margin-left: 10%;
    margin-bottom: 2rem;
}

.headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.title {
    font-family: 'Roboto';
    font-weight: 600;
    color: white;
}

.data {
    margin-top: 20px;
}