.container {
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    padding: 5px 0;
    background-color: white;
    border-bottom: 13px solid #f2c233;
}

.container img{
    width: 222px;
}