.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 5px 0;
    background-color: white;
    border-bottom: 13px solid #f2c233;
}

.container img {
    width: 222px;
}

.menu {
    display: flex;
    align-items: center;
    gap: 30px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: black;
}

.active {
    color: #f2c233;
}

.item {
    cursor: pointer;
    width: fit-content;
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Roboto';
}

.profile p {
    margin: 0;
    color: black;
    font-size: 14px;
    font-weight: 500;
}

.profile button {
    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: black;
    cursor: pointer;
}

.hamburgerMenu {
    display: none;
    background: none;
    border: none;
    padding: 0;
    height: fit-content;
    cursor: pointer;
}

.logout {
    display: none;
}

@media (max-width: 1150px) {

    .menu,
    .profile {
        display: none;
    }

    .menu {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        overflow: hidden;
        transition: opacity 0.3s ease, visibility 0s 0.3s, max-height 0.3s ease;
    }

    .menu.menuActive {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 98px;
        left: 0;
        background-color: white;
        width: 100%;
        z-index: 1000;
        padding: 20px 0;
        opacity: 1;
        visibility: visible;
        max-height: 100%;
        transition: opacity 0.3s ease, max-height 0.3s ease;
    }

    .logout.menuActive {
        display: block;
    }

    .logout.menuActive button {
        background: none;
        border: none;
        z-index: 1000;
        font-weight: 700;
        cursor: pointer;
    }

    .profile.menuActive button {
        color: #f2c233;
        font-weight: 700;
    }

    .container {
        align-items: center;
    }

    .hamburgerMenu {
        display: inline-block;
        margin-right: 20px;
    }
}