.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  display: flex;
  color: #f2c233;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15px;
  text-wrap: nowrap;
  gap: 5px !important;
}

.input {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid rgb(197, 197, 197);
  color: #959595;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.row{
  flex-direction: row;
  align-items: center;
}
