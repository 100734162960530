.tooltip {
    position: relative;
    width: fit-content;

    span {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #f2c233;
        border-radius: 50%;
        font-weight: 600;
        color: #f2c233;
        font-weight: 800;
        font-size: 10px;
    }

    div {
        visibility: hidden;
        position: absolute;
        text-wrap: wrap;
        min-width: 150px;
        width: min-content;
        left: 30px;
        font-size: 12px;
        top: -20px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #c3c3c3;
        background-color: #ffffff;
        color: #737373;
        line-height: normal;
        letter-spacing: normal;
        font-weight: 400;
    }

    &:hover {
        cursor: default;

        div {
            visibility: visible;
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1110px) {
    .tooltip div {
        width: 200px !important;
        left: -205px !important;
    }
}