.boxContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.graphContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 350px;
}

.graphTitle {
    display: flex;
    padding: 0px;
    align-items: center;
    justify-content: center;
    color: #f2c233;
}

h5.graphTitle{
margin: 0;
}

.noContributors {
    width: fit-content;
    margin: 0 auto;
    font-size: 1.125rem;
    font-weight: 500;
    color: #f2c233;
}

.pieContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.barContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.filtersContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 1000px) {
    .filtersContainer{
        flex-direction: column-reverse;
        gap: 20px;
    }
}

