.container {
  display: flex;
  gap: 10px;

  span {
    color: #f2c233;
    font-family: Roboto;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.15px;
  }
}

.color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: rgb(209, 38, 38);
}

.swatch {
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
  height: min-content;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
