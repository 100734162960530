.inputContainer {
  display: flex;
  flex-direction: row;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.inputSpan {
  margin-bottom: 0px;
  margin-top: 30px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #f2c233;
  z-index:3;
}

.input {
  margin-top: 5px;
  height: 25px;
  width: 250px;
  font-size: 16px;
  font-family: "Roboto";
  border: 2px solid #efefef;
  border-radius: 5px;
  padding-left: 15px;
  font-weight: 400;
  color: black;
  line-height: 24px;
  mix-blend-mode: normal;
}

.textarea {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  padding-left: 15px;
  padding-top: 2px;
  border: 2px solid #efefef;
  font-size: 16px;
  font-family: "Montserrat";
  mix-blend-mode: normal;
}

.inputErrorMessage {
  color: #ff0000;
  position: absolute;
  margin-top: 94px;
  font-weight: 300;
  font-size: 12px;
  font-family: "Roboto";
  z-index:5;
}
